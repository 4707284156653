exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dappy-js": () => import("./../../../src/pages/dappy.js" /* webpackChunkName: "component---src-pages-dappy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pixels-js": () => import("./../../../src/pages/pixels.js" /* webpackChunkName: "component---src-pages-pixels-js" */),
  "component---src-pages-roava-js": () => import("./../../../src/pages/roava.js" /* webpackChunkName: "component---src-pages-roava-js" */),
  "component---src-pages-writing-how-to-setup-react-project-on-a-virtual-private-server-js": () => import("./../../../src/pages/writing/how-to-setup-react-project-on-a-virtual-private-server.js" /* webpackChunkName: "component---src-pages-writing-how-to-setup-react-project-on-a-virtual-private-server-js" */),
  "component---src-pages-writing-index-js": () => import("./../../../src/pages/writing/index.js" /* webpackChunkName: "component---src-pages-writing-index-js" */),
  "component---src-pages-writing-window-document-not-defined-gatsby-build-js": () => import("./../../../src/pages/writing/window-document-not-defined-gatsby-build.js" /* webpackChunkName: "component---src-pages-writing-window-document-not-defined-gatsby-build-js" */),
  "component---src-pages-zoafia-js": () => import("./../../../src/pages/zoafia.js" /* webpackChunkName: "component---src-pages-zoafia-js" */)
}

